import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/LifeSkill2023/Roadsafty/1.jpg';
import p2 from 'assests/Photos/LifeSkill2023/Roadsafty/2.jpg';
import p3 from 'assests/Photos/LifeSkill2023/Roadsafty/3.jpg';
import p4 from 'assests/Photos/LifeSkill2023/Roadsafty/4.jpg';
import p5 from 'assests/Photos/LifeSkill2023/Roadsafty/5.jpg';
import p6 from 'assests/Photos/LifeSkill2023/Roadsafty/6.jpg';
import p7 from 'assests/Photos/LifeSkill2023/Roadsafty/7.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from 'views/LifeSkill2023/Sidebar2023';

const NationalRoadSafty2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
          src: p5,
          source:p5,
          rows: 1,
          cols: 1,
        },
        {
          src: p7,
          source: p7,
          rows: 1,
          cols: 1,
        },
        {
          src: p6,
          source: p6,
          rows: 1.5,
          cols: 2,
        },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Road Safety Week
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 5  &  Date: 9 FEBRUARY 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Road Safety Week is an annual event dedicated to promoting Road Safety Awareness and advocating for Safer Roads and Responsible Driving Behaviour. This year our focus centered on the theme ‘Be a road Safety Hero’ under scoring the importance of collaborative efforts in establishing a safe environment for every road user.  
        <br/>
        As part of the celebrations, Class 5 students participated in Poster Making Activity. It was conducted to spread awareness on the theme and reiterate traffic rules. This activity enabled students to have a better understanding of the traffic rules and follow them to ensure their own safety and that of others. The students participated with lot of zeal and enthusiasm.  

        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “OVER SPEED IS A KNIFE, THAT CUTS THE LIFE.”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 250 : 150}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default NationalRoadSafty2023;